@import '../../styles/variables';
@import '../../styles/mixins';

.footerBackground {
  background: $light-blue-background linear-gradient($light-blue-background, $color-white);
  padding-bottom: 2rem;

  .footerContainer {
    margin-inline: 0.625rem;

    @include bp(xs) {
      margin-inline: 2.5rem;
    }

    @include bp(m) {
      padding-inline: 3.125rem;
    }

    @include bp(ml) {
      max-width: 120rem;
      margin: 0 auto;
      padding-inline: 6.25rem;
    }
  }
}
