@import '../../styles/variables';
@import '../../styles/mixins';

.fileFieldContainer {
  display: flex;
  flex-direction: column;

  .fileField {
    background-color: $color-white-lighter;
    transition: padding 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 0.5rem;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    text-align: left;
    overflow: hidden;
    display: flex;
    outline: none;
    width: 3.9375rem;
    height: 3.9375rem;
    border-radius: 3.9375rem;

    @include bp(s) {
      flex-direction: row;
    }

    input {
      display: none;
    }

    .fileContainer {
      width: 100%;
      height: 8.125rem;

      @include bp(s) {
        width: 10rem;
      }

      .fileSelectedContainer {
        height: 100%;

        &:hover {
          opacity: 0.7;
          cursor: pointer;
        }

        .imageContainer {
          img {
            width: 3.9375rem;
            height: 3.9375rem;
            object-fit: cover;
            border-radius: 0.5rem;
          }
        }
      }
    }
  }
}

.fileError {
  margin-top: 1rem;
  font-family: $font-inter;
  font-weight: 400;
  font-size: 1.125rem;
  color: $label-error;
  flex-basis: 100%;
  order: 3;
}
