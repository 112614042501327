@import '../../styles/variables';

.profileButton {
  position: relative;

  .button {
    background-color: transparent;
    color: $black-color;

    >img {
      margin-right: 0.5rem;
    }

    &:hover {
      background-color: transparent;
      color: $black-color;
    }
  }
}