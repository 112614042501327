@import '../../styles/variables';
@import '../../styles/mixins';

.fileFieldContainer {
  display: flex;
  flex-direction: column;
  font-family: $font-lexend;

  h5 {
    color: $black-color;
    font-size: 1.25rem;
    font-weight: 300;
    margin-bottom: 0.9375rem;
  }

  .fileField {
    transition: padding 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 0.5rem;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    text-align: left;
    overflow: hidden;
    display: flex;
    outline: none;
    border-radius: 0.9375rem;
    border: 0.125rem dashed $primary-main;
    box-shadow: $profile-page-box-shadow;
    height: 7.625rem;

    @include bp(s) {
      flex-direction: row;
    }

    input {
      display: none;
    }

    .fileContainer {
      .fileSelectedContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          opacity: 0.7;
          cursor: pointer;
        }

        .imageContainer {
          width: 8.75rem;
          height: 6.875rem;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 0.5rem;
          }
        }

        .innerFileSelectContainer {
          display: flex;
          align-items: center;
          gap: 1.0625rem;
          border-radius: 0.625rem;
          height: 100%;
          color: $color-grey;

          .innerFileIcon {
            color: $color-grey;

            &.errorFile {
              color: $red-color;
              opacity: 0.8;
            }

            &.successFile {
              color: $primary-main;
              opacity: 0.8;
            }
          }

          .innerFileSelectLabel {
            color: $black-color;
            font-size: 1.25rem;
            font-weight: 400;
          }
        }
      }
    }
  }

  .label {
    margin-top: 0.625rem;
    text-align: right;

    p {
      margin: 0;
      line-height: 1.57143;
      font-size: 0.75rem;
      font-family: $font-lexend;
      font-weight: 400;
      color: $coming-soon-subheadline;

      &.selectedFileLabel {
        margin-top: 1.25rem;
        margin-bottom: 0.375rem;
        color: $color-grey;
        font-size: 0.688rem;

        .selectedFileIcon {
          color: $color-grey;
          margin-right: 0.375rem;
        }
      }
    }

    .fileError {
      font-family: $font-lexend;
      font-weight: 400;
      font-size: 0.75rem;
      color: $red-color;
    }
  }

  .multipleImageContainer {
    border: 0.063rem dashed $color-grey-light;
    margin-top: 1.25rem;
    border-radius: 0.625rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.625rem;
    padding: 0.625rem;

    .imageContainer {
      width: 8.75rem;
      height: 6.875rem;
      position: relative;

      .delete {
        position: absolute;
        right: 0;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 5rem;
        height: 1.5rem;
        width: 1.5rem;

        &:hover {
          cursor: pointer;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.5rem;
      }
    }
  }
}
