@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.modalContainer {
  .planConfirmationContainer {
    display: flex;
    flex-direction: column;
    font-family: $font-lexend;
    gap: 2.8125rem;
    text-align: center;
    align-items: center;

    .title {
      font-size: 1.75rem;
    }

    .daysInfo {
      font-size: 1rem;
      font-weight: 300;
      color: $primary-main;

      span {
        font-weight: 400;
        font-size: 1.25rem;
      }
    }

    .payDetails {
      font-size: 1.25rem;
      font-weight: 300;
      color: $color-grey-dark;
      text-align: center;
    }

    button {
      max-width: 28rem;
    }
  }
}
