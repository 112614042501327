@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.loginSectionContainer {
  padding-top: 2.5rem;
  text-align: center;
  color: $lighter-bright-color;

  .separatorWrapper {
    margin: 2.5rem 0;
  }

  .separator {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 1.125rem;
    position: relative;
  }
  
  .line {
    position: absolute;
    margin-top: 0.625rem;
    left: 0;
    right: 0;
    border-top: 0.06rem solid $color-grey-background-dark;
  }
  
  .word {
    background-color: $bright-color;
    padding: 0 1.625rem;
    position: relative;
    z-index: 1;
  }
}
