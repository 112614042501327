@import '../../../styles/mixins';
@import '../../../styles/variables';

.footerLinks {
  display: grid;
  gap: 1.25rem;
  padding: 3.125rem 0.625rem;
  justify-content: center;
  text-align: center;

  @include bp(xs) {
    text-align: left;
    justify-content: left;
    grid-template-columns: repeat(2, 1fr);
    padding-inline: 0rem;
  }

  @include bp(m) {
    grid-template-columns: 1fr 1fr 1fr 7.5rem;
    gap: 1.875rem;
  }

  .footerLinksColumn {
    .footerLinksList {
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 0.625rem;

      @include bp(m) {
        gap: 1.5rem;
      }

      .footerLogo {
        svg {
          width: 14rem;
        }
      }

      .footerLinksTitle {
        display: flex;
        font-weight: 300;
        color: $color-grey;
        height: 1.9375rem;
        align-items: flex-end;
        justify-content: center;

        @include bp(xs) {
          justify-content: flex-start;
        }
      }

      .footerLinkItem {
        font-size: 1.125rem;

        > a {
          &:hover {
            color: $color-grey;
          }
        }

        .footerSocialLink {
          justify-content: center;
          display: flex;
          gap: 0.5rem;

          @include bp(xs) {
            justify-content: left;
          }

          img {
            width: 1.5625rem;
            height: 1.5625rem;
          }
        }
      }
    }
  }
}
