$font-inter: 'Inter', sans-serif;
$font-poppins: 'Poppins', sans-serif;
$jost: 'Jost', sans-serif;
$dmSans: 'DM Sans', sans-serif;
$josefinSans: 'Josefin Sans', sans-serif;
$font-lexend: 'Lexend', sans-serif;

$error-text: rgb(122, 12, 46);
$error-background: rgb(255, 231, 217);
$error-icon: rgb(255, 72, 66);

$primary-main: #3961f5;
$primary-light: #2e8df0;
$primary-dark: #41c314;
$primary-extra-dark: #2e8b0e;
$primary-darker: $primary-extra-dark;
$primary-darkest: $primary-extra-dark;
$primary-light-background: rgba(66, 204, 18, 0.02);

$color-white: #ffffff;
$color-white-light: #f6f4ef;
$color-white-lighter: #f5f5f5;
$color-white-ghost: #f8fafb;

$primary-button-background: #3961f5;
$primary-button-background-hover: #6180f3;
$primary-button-shadow: rgba(28, 49, 68, 0.12) 0 0.25rem 0.5rem 0;

$secondary-button-background: #ecf0fe;
$secondary-button-background-hover: #f0f3fc;

$disabled-button-background: rgba(145, 158, 171, 0.24);

$color-grey: rgb(126, 136, 143);
$color-grey-light-2: #a9a9a9;
$color-grey-light: #d9d9d9;
$color-grey-dark: #5e5e5e;
$color-grey-background: #f2f2f2;
$color-grey-background-dark: #e8e8e8;
$color-grey-very-light: #c9c9c9;
$color-grey-medium-light: #a9a9a9;
$color-grey-light-line: rgba(218, 222, 227, 0.1);
$color-grey-text: #8c8c8c;
$color-grey-rich-text: rgba(217, 217, 217, 0.2);
$color-grey-rich-text-hover: rgba(217, 217, 217, 0.3);
$color-grey-icon: #131a29;
$color-grey-icon: #828890;
$color-grey-border: #ededed;
$color-grey-translucent: #f9f9f9;
$color-grey-translucent-light: #f3f3f3;
$color-orange: #e9471d;
$color-orange-darker: #ee4216;
$color-blue-steel: #405e8c;
$color-blue-soft-light: #e2e7fc;
$color-teal: #33cca0;
$color-teal-light: #72e2c2;
$color-green-bright: #11bc8b;
$color-brilliant-white: #ecf0fe;
$color-pale-blue: #e2e8fe;

$label: rgb(145, 158, 171);
$label-focused: $primary-light;
$label-error: #d32f2f;

$input-border: rgba(145, 158, 171, 0.32);
$input-border-focused: $primary-light;
$input-border-error: $label-error;
$input-error-text: $label-error;

$background-color: #e8e9eb;
$background-transparent-white: rgba(255, 255, 255, 0);
$background-color-grey: #f2f7fc;
$background-light-grey: rgba(145, 158, 171, 0.12);
$dark-background: #333333;

$color-blue-transparent-2: rgba(34, 51, 84, 0.2);
$color-blue-transparent-1: rgba(34, 51, 84, 0.1);

$separator-color: rgba(227, 87, 36, 0.2);
$input-border-color: rgba(227, 87, 36, 0.5);
$placeholder-color: rgba(227, 87, 36, 0.6);

$bright-color: #ffffff;
$lighter-bright-color: #b7b7b7;
$black-color: #000000;
$lighter-black-color: #1c1c1c;

$red-color: #e13119;
$red-color-darker: #e04321;
$red-color-brighter: #efc5bd;

$warning-color: #f19a3e;
$warning-color-brighter: #f6c48d;
$warning-color-darker: #98540b;

$sidebar-color: #313638;
$navigation-color: #313638;

$coming-soon-subheadline: rgb(99, 115, 129);

$gradient-color-blue-dark: #283f95;
$gradient-color-blue-light: #ced4ee;
$gradien-color-grey-light: #e2e4ec;

$color-white-opacity-50: rgba(255, 255, 255, 0.5);
$color-white-opacity-58: rgba(255, 255, 255, 0.58);
$color-white-opacity-70: rgba(255, 255, 255, 0.7);

$color-black-opacity-25: rgba(0, 0, 0, 0.25);

$color-progress-bar-gradient-1: linear-gradient(0deg, #3961f5 0%, #72e2c2 100%);
$color-progress-bar-gradient-1-90deg: linear-gradient(
  90deg,
  #3961f5 0%,
  #72e2c2 100%
);
$color-progress-bar-gradient-2: linear-gradient(0deg, #72e2c2 0%, #3961f5 100%);
$color-progress-bar-gradient-2-90deg: linear-gradient(
  90deg,
  #72e2c2 0%,
  #3961f5 100%
);
$gradient-color-black: rgba(251, 251, 251, 0);
$grey-gradient: linear-gradient(0deg, #f9f9f9 0%, #f3f3f3 100%);
$gradient-color-light-grey: linear-gradient(180deg, #fcfcfc 0%, #f4f4f4 100%);

$light-blue-background-gradient: linear-gradient(
  180deg,
  #f2f7fc 50%,
  rgba(255, 255, 255, 0) 94.81%
);

$lightest-blue-background: #e9f0fa;
$light-blue-background: #f2f7fc;

$lightest-grey-background: #fbfbfb;
$light-grey-background: #eeeeee;
$dark-grey-text: #8c8c8c;

$color-breadcrumb: #cecece;
$darker-grey-text: #595959;
$light-grey-text: #b7b7b7;
$dark-grey-text: rgba(34, 51, 84, 0.7);

$orange-background: #ebb16b;
$organge-dark-text: #bd6800;
$orange-background-dark: #e5912b;
$orange-background-light: #ffe8cd;

$light-green-background: #72e2c2;
$light-green-background-dark: #11bc8b;
$light-green-background-light: #d2f3ea;

$pink-background: #fe2ebb;
$pink-background-dark: #fe2ebb;
$pink-background-light: #ffe7f7;

$previewCardTop: #1a1d24;
$pill-color: #405e8c;
$pill-background-color: #e9f0fa;

$assessment-button-color: #e5912b;
$dark-blue-button: #3462ff;

$grey-dark: #bebebe;

$form-container-box-shadow: 0 0.5rem 0.4375rem 0 rgba(0, 0, 0, 0.02);

$mui-dialog-box-shadow: rgba(145, 158, 171, 0.24) 0 0 0.25rem 0,
  rgba(145, 158, 171, 0.24) 0 1.5rem 3rem 0;

$profile-page-box-shadow: 0px 8px 7px 0px rgba(0, 0, 0, 0.02);

$black-lighest-background: rgba(0, 0, 0, 0.05);

$navigation-container-shadow: rgba(0, 0, 0, 0.1);

$text-stroke-shadow: -0.0625rem -0.0625rem 0 $black-color,
  0.0625rem -0.0625rem 0 $black-color, -0.0625rem 0.0625rem 0 $black-color,
  0.0625rem 0.0625rem 0 $black-color;
$grey-border: #dadada;
$box-shadow: 0 0.25rem 0.4375rem 0 rgba(0, 0, 0, 0.05);
