@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.remindForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0.5625rem;

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3.25rem;
    position: relative;

    > div {
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      :global(.MuiFormLabel-root) {
        font-family: $font-lexend;
        font-size: 1.25rem;
        font-weight: 300;
        color: $black-color;
        margin-top: -1rem;
        padding: 0;
      }

      @include bp(m) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
