@import '../../../styles/mixins';
@import '../../../styles/variables';

.footerCopyright {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 1rem;

  @include bp(s) {
    flex-direction: row;
    justify-content: space-between;
  }

  .footerCopyRightText {
    color: $color-grey-medium-light;
    font-weight: 300;
  }

  .footerCreatorLogo{
    img{
      height: 1.5rem;
    }
  }
}
