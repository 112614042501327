@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.planModal {
  font-family: $font-lexend;

  :global(.MuiDialog-paperScrollPaper) {
    max-width: 77.1875rem !important;
    border: 0.0625rem solid $color-grey-background-dark;

    h2 {
      padding: 0 !important;
      align-self: flex-end;

      button {
        margin-right: 1.25rem;
        margin-top: 1.25rem;
      }
    }
  }

  :global(.MuiDialogContent-root) {
    padding: 0 !important;
  }

  .plansContainer {
    padding: 0 1rem 3.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3.375rem;

    @include bp(xs) {
      padding: 0 5rem 3.25rem;
    }

    .title {
      font-size: 1.75rem;
      text-align: center;
    }

    .planCardsContainer {
      display: flex;
      flex-direction: column;
      gap: 1.625rem;

      @include bp(l) {
        flex-direction: row;
      }
    }

    .contactsContainer {
      display: flex;
      flex-direction: column;
      max-width: 33rem;
      align-items: flex-start;
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      @include bp(xs) {
        align-items: flex-end;
      }

      .contacts {
        font-size: 1.3rem;
        font-weight: 300;

        @include bp(xs) {
          font-size: 1.25rem;
          line-height: 2.0625rem;
        }

        span {
          font-weight: 400;
          color: $primary-main;
          text-decoration: underline;
        }
      }

      .workingHours {
        font-weight: 400;
        color: $color-grey-dark;
        font-size: 1rem;
      }
    }
  }
}
