@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.registrationForm {
  width: 100%;
  display: flex;
  flex-direction: column;

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    position: relative;

    >:nth-child(n+2) {
      margin-top: 2.125rem;
    }

    >:nth-child(4),
    >:last-child {
      margin-top: 0;
    }

    :global(.MuiCheckbox-root) {
      scale: 1.25;
    }

    .policiesLink {
      text-decoration: underline;
    }

    >div {
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      :global(.MuiFormLabel-asterisk){
        color: $primary-main;
      }

      :global(.MuiFormLabel-root) {
        font-family: $font-lexend;
        font-size: 1.25rem;
        font-weight: 400;
        color: $black-color;
        margin-top: -1.25rem;
        margin-left: -1rem;
      }

      :global(.MuiInputBase-root) {
        background-color: transparent;
        border: 1px solid $grey-dark;
        border-radius: 15px;
      }

      @include bp(m) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .alreadyRegisteredContainer {
    color: $black-color;
    font-weight: 400;
    font-size: 18px;
    margin-top: 30px;

    .alreadyRegisteredLink {
      color: $primary-main;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
