@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.loginForm {
  width: 100%;
  display: flex;
  flex-direction: column;

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    position: relative;

    > :nth-child(2) {
      margin-top: 2.125rem;
    }

    > div {
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      :global(.MuiFormLabel-root) {
        font-family: $font-lexend;
        font-size: 1.25rem;
        color: $black-color;
        margin-top: -1.1rem;
        font-weight: 300;
        line-height: 1.875rem;
      }

      label {
        padding-left: 0;
      }

      @include bp(m) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  .linksContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 1.69rem;
    text-decoration: underline;
    color: $primary-main;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.875rem;

    a {
      &:nth-child(1) {
        color: $black-color;
        text-decoration: underline;
        text-decoration-color: $black-color;
        text-align: left;
      }
    }

    span {
      cursor: pointer;
    }
  }

  .noAccount {
    width: 100%;
    margin-top: 3.125rem;
    padding-top: 2.125rem;
    border-top: 0.063rem solid $color-grey-light;
    text-align: center;
    font-family: $font-lexend;
    font-weight: 400;
    font-size: 1.125rem;
    color: $primary-extra-dark;

    a {
      font-family: $font-lexend;
      font-weight: 400;
      color: $primary-main;
      text-decoration: underline;
    }
  }
}
