@import '../../styles/variables';
@import '../../styles/mixins';

.staticDateContainer {
  display: flex;

  :global(.MuiPickersCalendarHeader-label) {
    font-family: $dmSans;
    font-weight: 400;
    color: $primary-darkest;
    font-size: 1.375rem;
    text-transform: capitalize;
  }

  :global(.MuiPickerStaticWrapper-content) {
    background-color: transparent;
  }

  :global(.MuiPickersCalendarHeader-root) {
    margin-bottom: 2.5rem;
  }

  :global(.MuiSvgIcon-root) {
    color: $primary-darkest;
  }

  :global(.MuiDayPicker-weekDayLabel) {
    font-family: $dmSans;
    font-weight: 400;
    font-size: 1.25rem;
    color: $primary-darkest;
    width: 3.5rem;
  }

  :global(.MuiPickersDay-root) {
    font-family: $dmSans;
    font-weight: 400;
    font-size: 1.125rem;
    color: $primary-darkest;
    width: 3.5rem;
    border-radius: 0;
    background-color: transparent;
  }

  :global(.MuiPickersDay-root.Mui-selected) {
    background-color: $primary-main !important;
    color: $color-white;
  }

  :global(.PrivatePickersYear-yearButton.Mui-selected) {
    background-color: $primary-main !important;
    color: $color-white;
  }

  :global(.MuiCalendarOrClockPicker-root) {
    > div {
      width: 100%;
      max-height: none;
    }
  }

  :global(.MuiCalendarPicker-root) {
    width: 100%;
    max-height: none;
  }
}

.textField {
  width: 100%;

  :global(.MuiAutocomplete-inputRoot) {
    padding-right: 0.563rem !important;
    padding-left: 0.7rem !important;
  }

  :global(.MuiAutocomplete-root) {
    width: 100%;
  }

  :global(.MuiAutocomplete-input) {
    padding-left: 0.8rem !important;
  }

  :global(.MuiInputBase-root) {
    background-color: transparent;
  }

  :global(.MuiInputBase-root:hover) {
    border-radius: 0;
  }

  :global(.MuiInputBase-root) {
    border-radius: 1.25rem;
    border: 0.0625rem solid $color-grey-background-dark;

    &:hover,
    &:focus {
      border-radius: 0.9375rem;
    }

    > fieldset {
      border: none;
    }

    textarea {
      padding-left: 0.7rem;
    }
  }

  &.currencyInput {
    p {
      margin: 0.063rem 0 0;
      line-height: 1.5;
      font-family: $jost;
      font-weight: 400;
      color: rgb(99, 115, 129);

      &:global(.Mui-error) {
        color: $input-error-text;
        margin: 0.5rem 0.875rem 0;
        font-size: 0.75rem;
        line-height: 1.66;
      }
    }
  }

  &.timeInput {
    :global(.MuiFormHelperText-sizeMedium) {
      position: absolute;
      top: 3.125rem;
    }
  }

  label {
    font-family: $font-lexend;
    color: $label;
    font-size: 0.875rem;
    line-height: 1.4374em;
    font-weight: 300;
    display: block;
    transform-origin: left top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 1.5rem);
    position: absolute;
    padding: 0 0 0 1rem;
    margin-left: -0.8rem;

    &:global(.Mui-focused) {
      color: $label-focused;
      margin-top: -0.3rem;
    }

    &:global(.MuiFormLabel-filled) {
      margin-top: -0.3rem;
    }

    &:global(.MuiInputLabel-shrink) {
      margin-top: -0.3rem;
    }

    &:global(.Mui-error) {
      color: $label-error;
    }
  }

  > div {
    border-radius: 0;
    font-size: 0.875rem;
    font-family: $font-lexend;
    font-weight: 300;
    line-height: 1.4375;

    input {
      font-family: $font-lexend;
    }

    fieldset {
      border-color: $input-border;

      legend {
        width: auto;
      }
    }

    &:global(.Mui-focused) {
      fieldset {
        border-color: $input-border-focused !important;
      }
    }

    &:global(.Mui-error) {
      fieldset {
        border-color: $input-border-error !important;
      }
    }
  }

  p {
    font-family: $font-lexend;
    margin: 0.5rem 0.875rem 0;

    &:global(.Mui-error) {
      color: $input-error-text;
    }
  }
}

.switcherContainer {
  margin-top: 0.625rem;

  :global(.MuiSwitch-switchBase) {
    color: $red-color;
  }

  :global(.Mui-checked) {
    color: rgb(0, 171, 85) !important;
  }

  :global(.Mui-checked + .MuiSwitch-track) {
    background-color: rgb(0, 171, 85) !important;
  }

  :global(.MuiSwitch-track) {
    background-color: $red-color;
  }

  :global(.MuiFormControlLabel-label) {
    line-height: 1.57143;
    font-size: 0.875rem;
    font-family: $font-lexend;
    font-weight: 400;
  }
}

.checkboxContainer {
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;

  :global(.MuiSvgIcon-root) {
    width: 1rem;
    height: 1rem;
  }

  :global(.MuiFormControlLabel-label) {
    font-family: $font-lexend;
    font-weight: 300;
    color: $black-color;
    font-size: 1rem;
  }

  :global(.Mui-checked) {
    color: $primary-main !important;
  }
}

.editorContainer {
  &.error {
    div:first-of-type {
      border-color: $red-color;
    }
  }

  .editorError {
    font-family: $font-lexend;
    font-weight: 400;
    font-size: 0.75rem;
    color: $red-color;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin: 0.5rem 0.875rem 0;
  }
}

.checkBoxError {
  color: $label-error;
  font-weight: 400;
  font-size: 0.75rem;
  text-align: left;
  margin-top: -1.5rem !important;
  margin-left: 0.875rem;
}

.forgotPassword {
  text-align: right;
  font-size: 0.8125rem;
  padding-bottom: 0.5rem;
  cursor: pointer;
}

.selectInputIconComponent {
  margin-right: 1.25rem;
}
