@import '../../styles/variables';
@import '../../styles/mixins';

.navigationContainer {
  display: flex;
  justify-content: center;
  box-shadow: 0 -0.375rem 0.625rem 0.313rem $navigation-container-shadow;
  height: 5.625rem;

  @include bp(l) {
    height: 6.625rem;
  }

  .innerNavigation {
    width: 90%;
    padding: 2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      svg {
        width: 14rem;
      }
    }

    .searchbarContainer {
      width: 70%;
      margin: 1.25rem auto;

      @include bp(sm) {
        width: 30%;
      }

      .searchbar {
        max-width: 26.375rem;

        > div {
          border: 0.063rem solid $color-grey-background-dark;
          background-color: transparent;

          .searchIcon {
            cursor: pointer;
          }
        }
      }
    }

    .buttonContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 1.125rem;

      > button {
        font-size: 1rem;
        font-weight: 500;
        padding: 0.781rem 1.625rem;
        width: auto;
        text-transform: none;
      }

      .activeAccountStatus {
        font-size: 1rem;
        font-weight: 300;
        color: $primary-main;

        span {
          font-weight: 600;
        }
      }

      .accountStatus {
        font-size: 1rem;
        font-weight: 600;
        color: $primary-main;

        span {
          font-weight: 400;
        }
      }
    }
  }

  .mobileMenuLinks {
    padding: 2rem 0;
    top: 5.625rem;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 99;
    box-shadow: $color-blue-transparent-2 0 0.125rem 0.5rem -0.188rem,
      $color-blue-transparent-1 0 0.313rem 1.375rem -0.25rem;
    background-color: $color-white;
  }
}
