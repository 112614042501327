@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';

.cardContainer {
  font-family: $font-lexend;
  border: 0.0625rem solid $grey-border;
  padding: 1.8rem;
  border-radius: 0.5625rem;
  max-width: 21rem;
  box-shadow: $box-shadow;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;

  @include bp(xs) {
    gap: 1.75rem;
    padding: 2.125rem;
  }

  &.isBestValue {
    background-color: $primary-main;
    color: $color-white;
    border: none;

    button {
      background-color: $color-white;
      color: $primary-main;
    }
  }

  .months {
    font-size: 1.2rem;
    font-weight: 300;

    @include bp(xs) {
      font-size: 1.75rem;
    }

    span {
      font-weight: 400;
    }
  }

  .divider {
    padding-right: 0.3125rem;
    border-bottom: 0.0625rem solid $grey-border;
  }

  .price {
    font-size: 1.3rem;
    font-weight: 600;

    @include bp(xs) {
      font-size: 1.875rem;
    }
  }

  .prosList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;

    @include bp(xs) {
      gap: 1.5625rem;
    }

    .pros {
      display: flex;
      flex-direction: row;
      font-size: 1rem;
      gap: 0.5rem;
      align-items: center;

      @include bp(xs) {
        font-size: 1.125rem;
      }

      div {
        width: 1.125rem;
        height: 1.125rem;

        div {
          width: 1.125rem;
          height: 1.125rem;
        }
      }
    }
  }

  .bestValueLabel {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.4375rem 1.5625rem;
    background-color: $color-white;
    color: $primary-main;
    border-bottom-left-radius: 0.5625rem;
  }
}
