@import '../../../styles/variables';
@import '../../../styles/mixins';

.navLinkContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  color: $color-grey-text;
  font-family: $font-inter;

  @include bp(l) {
    flex-direction: row;
  }

  @include bp(xl) {
    gap: 4rem;
  }

  .navLink {
    font-size: 1.25rem;
    text-decoration: none;
    cursor: pointer;
    transition: color 300ms;

    &:hover {
      color: $primary-main;
    }

    &.activeNavLink {
      color: $primary-main;
      font-weight: 500;
    }
  }

  .buttonsContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include bp(l) {
      flex-direction: row;
    }

    .activeAccountStatus {
      font-size: 1rem;
      font-weight: 300;
      color: $primary-main;

      span {
        font-weight: 600;
      }
    }

    .accountStatus {
      font-size: 1rem;
      font-weight: 600;
      color: $primary-main;

      span {
        font-weight: 400;
      }
    }
  }
}
