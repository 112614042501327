@import '../../styles/variables';

.button {
  height: 2.25rem;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: $color-white;

  &:global(.MuiButton-containedPrimary) {
    background-color: $primary-button-background;
    box-shadow: none;
    border-radius: 1.5rem;
    padding: 0.688rem 2.5rem;
    font-family: $font-lexend;
    text-transform: capitalize;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.5rem;
    height: 3rem;
    width: 100%;

    &:global(.Mui-disabled) {
      box-shadow: none;
      background-color: $disabled-button-background;
    }

    &:hover {
      box-shadow: none;
      background-color: $primary-button-background-hover;
      color: $color-white;
    }
  }

  &:global(.MuiButton-containedSecondary) {
    box-shadow: none;
    border-radius: 1.5rem;
    padding: 0.688rem 2.5rem;
    font-family: $font-lexend;
    text-transform: capitalize;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.5rem;
    height: 3rem;
    width: 100%;
    background-color: $secondary-button-background;
    color: $primary-main;

    &:global(.Mui-disabled) {
      box-shadow: none;
      background-color: $disabled-button-background;
    }

    &:hover {
      box-shadow: none;
      background-color: $secondary-button-background-hover;
      color: $primary-main;
      opacity: 0.7;
    }
  }

  &:global(.MuiButton-outlinedPrimary) {
    background-color: unset;
    border: 0.063rem solid $primary-button-background;
    color: $primary-button-background;
    box-shadow: none;
    border-radius: 1.5rem;
    padding: 0.688rem 2.5rem;
    font-family: $font-lexend;
    text-transform: capitalize;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.5rem;
    height: 3rem;
    width: 100%;

    &:global(.Mui-disabled) {
      box-shadow: none;
      background-color: $disabled-button-background;
    }

    &:hover {
      box-shadow: none;
      background-color: $primary-button-background-hover;
      color: $primary-main;
    }
  }

  &:global(.MuiButton-textPrimary) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-weight: 700;
    line-height: 1.71429;
    font-size: 0.875rem;
    text-transform: capitalize;
    font-family: $font-lexend;
    min-width: 4rem;
    padding: 0.375rem 0.5rem;
    border-radius: 0.5rem;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: $primary-button-background;
  }

  &.saveButton {
    height: auto;
    padding: 0.375rem 3rem;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.textButton {
    opacity: 0.8;
  }

  &.isActive {
    opacity: 1;
  }

  &.onlyIcon {
    padding-left: 1rem;
    padding-right: 1rem;
    min-width: 2.5rem;

    :global(.MuiButton-startIcon) {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .loadingIcon {
    margin-right: 0.25rem;
  }
}
