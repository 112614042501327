@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.authContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 3rem;
  background: $light-blue-background-gradient;
  min-height: 55rem;

  .leftContainer {
    position: relative;
    width: 90%;
    max-width: 40.25rem;
    border-radius: 1.25rem;
    border: 0.0625rem solid $color-grey-background-dark;
    box-shadow: 0 0.5rem 0.4375rem 0 rgba(0, 0, 0, 0.02);
    margin: 0 auto;
    background: $color-white;
    margin-top: 4.75rem;
    margin-bottom: 4.75rem;

    @include bp(m) {
      width: 100%;
    }

    .leftContainerInner {
      padding: 3.12rem 1.5rem;

      @include bp(s) {
        padding: 3.12rem 6.12rem;
      }

      .title {
        font-family: $font-lexend;
        font-weight: 400;
        font-size: 1.75rem;
        color: $primary-main;
        text-align: center;
        margin-top: 1.25rem;
        color: $lighter-black-color;
        line-height: 2.0625rem;
        text-align: center;
      }

      .subTitle {
        font-family: $font-lexend;
        font-weight: 400;
        margin-top: 1.75rem;
        color: $color-grey;
        font-size: 1.25rem;
        color: $dark-grey-text;
        text-align: center;
      }
    }

    .goBackBtn {
      position: absolute;
      top: -2.5625rem;
    }
  }

  .rightContainer {
    width: 100%;
    padding-bottom: 5rem;
    position: relative;
    display: flex;
    height: 37.5rem;

    @include bp(l) {
      height: auto;
    }

    .backgroundImage {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: top;

      @include bp(l) {
        border-bottom-left-radius: 6.25rem;
        border-top-left-radius: 6.25rem;
      }
    }

    .welcomeImage {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;

      @include bp(l) {
        border-bottom-left-radius: 6.25rem;
        border-top-left-radius: 6.25rem;
      }
    }

    .circleImage {
      position: absolute;
      bottom: -6.875rem;
      right: 0.75rem;
      z-index: 1;
      display: none;

      @include bp(l) {
        display: block;
      }
    }

    .leftContainerInner {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      z-index: 1;
      margin-left: 1.5rem;
      margin-bottom: 1.5rem;

      @include bp(l) {
        margin-left: 4.375rem;
        margin-bottom: 4.375rem;
      }

      .headline {
        font-size: $font-lexend;
        font-weight: 400;
        font-size: 1.875rem;
        color: $bright-color;
        margin-bottom: 2.125rem;
      }

      .list {
        list-style-type: none;

        .listItem {
          display: flex;
          align-items: center;
          color: $bright-color;
          font-size: 1.125rem;
          font-size: $font-lexend;
          margin-bottom: 1.375rem;
          font-weight: 400;

          .checkmark {
            margin-right: 1.25rem;
          }
        }
      }

      .registerButton {
        color: $bright-color;
        border: none;
        text-decoration: underline;
        font-size: 1.375rem;
        background-color: transparent;
        font-size: $font-lexend;
        font-weight: 400;

        &:hover,
        &:focus {
          cursor: pointer;
        }
      }
    }
  }

  .passwordRemindSection {
    margin-top: 9rem;
  }
}
