@import '../../styles/variables';
@import '../../styles/mixins';

.publicSearchInput {

  :global(.MuiInputBase-root) {
    border-radius: 3.25rem;
    border-color: $color-grey-background-dark;
    padding-left: 1rem;

    &:hover {
      border-radius: 3.25rem;
    }
  }

  :global(.MuiInputAdornment-root) {
    padding-top: 0.275rem;
    cursor: pointer;
  }
}
