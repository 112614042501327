@import '../../styles/variables';

.popover {
  position: absolute;
  width: 16.25rem;
  top: 110%;
  right: 20%;
  background-color: $bright-color;
  border: 1px solid $color-grey-background-dark;
  border-radius: 1.25rem;
  box-shadow: 0rem 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
  z-index: 1;

  .menu {
    display: flex;
    gap: 1.438rem;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    padding: 1.375rem 1.563rem;

    li {
      display: flex;
      align-items: center;
      cursor: pointer;

      .menuIcon {
        margin-right: 0.5rem;
      }
    }
  }
}